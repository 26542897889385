import { IDeepLocation } from '@local/Types/MatchingApi.types'
import { IProfileLocationSectionContent } from '@local/Types'
import { deepLocationToSwedish } from '@local/Utils/Helpers'
import { Box, Chip, Stack, Typography } from '@mui/material'
import React from 'react'

interface ProfileLocationSectionProps {
  content: IProfileLocationSectionContent
  otherPreferences?: string[]
  locations: IDeepLocation[]
}

export const ProfileLocationSection: React.FC<ProfileLocationSectionProps> = ({
  otherPreferences = [],
  locations,
  content,
}) => {
  const locationsInSweden = locations.filter(
    (location) => location.type.toLowerCase() !== 'country'
  )
  const locationsAbroad = locations.filter(
    (location) => location.type.toLowerCase() === 'country'
  )

  return (
    <Stack spacing={2}>
      <Typography variant="h6">{content.title}</Typography>
      {otherPreferences.length > 0 && (
        <Box>
          <Typography variant="body1" gutterBottom>
            {content.otherPreferencesTitle}
          </Typography>
          <Stack direction="row" spacing={1} flexWrap={'wrap'} useFlexGap>
            {otherPreferences.map((preference) => (
              <Chip
                key={`${preference}`}
                label={
                  content[preference as keyof IProfileLocationSectionContent]
                }
              />
            ))}
          </Stack>
        </Box>
      )}
      <Box>
        <Typography variant="body1" gutterBottom>
          {content.locationsSwedenTitle}
        </Typography>
        <Stack direction="row" spacing={1} flexWrap={'wrap'} useFlexGap>
          {locationsInSweden.map((location) => (
            <Chip
              key={`${location.id}`}
              label={deepLocationToSwedish(location)}
            />
          ))}
        </Stack>
      </Box>
      {locationsAbroad.length > 0 && (
        <Box>
          <Typography variant="body1">
            {content.locationsAbroadTitle}
          </Typography>
          <Stack direction="row" spacing={1} flexWrap={'wrap'} useFlexGap>
            {locationsAbroad.map((location) => (
              <Chip key={`${location.id}`} label={location.name} />
            ))}
          </Stack>
        </Box>
      )}
    </Stack>
  )
}
