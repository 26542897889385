import { IProfileWorkTypeSectionContent } from '@local/Types'
import { Box, Chip, Stack, Typography } from '@mui/material'
import React from 'react'

interface ProfileWorkTypeSectionProps {
  content: IProfileWorkTypeSectionContent
  formsOfEmployment?: string[]
  extentsOfEmployment: string[]
}

export const ProfileWorkTypeSection: React.FC<ProfileWorkTypeSectionProps> = ({
  content,
  formsOfEmployment = [],
  extentsOfEmployment = [],
}) => (
  <Stack spacing={2}>
    <Typography variant="h6">{content.title}</Typography>
    <Box>
      <Typography variant="body1" gutterBottom>
        {content.formsOfEmploymentTitle}
      </Typography>
      <Stack direction="row" spacing={1} flexWrap={'wrap'} useFlexGap>
        {formsOfEmployment.map((preference) => (
          <Chip
            key={`${preference}`}
            label={content[preference as keyof IProfileWorkTypeSectionContent]}
          />
        ))}
      </Stack>
    </Box>
    <Box>
      <Typography variant="body1" gutterBottom>
        {content.extentsOfEmploymentTitle}
      </Typography>
      <Stack direction="row" spacing={1} flexWrap={'wrap'} useFlexGap>
        {extentsOfEmployment.map((preference) => (
          <Chip
            key={`${preference}`}
            label={content[preference as keyof IProfileWorkTypeSectionContent]}
          />
        ))}
      </Stack>
    </Box>
  </Stack>
)
