import React from 'react'
import { Delete } from '@mui/icons-material'
import {
  IconButton,
  TableCell,
  TableRow,
  TableRowProps,
  Tooltip,
  styled,
} from '@mui/material'
import { useWindowSize } from '@local/Utils/Hooks/useWindowSize'

const CandidateTableRow = styled(TableRow)<{
  disabled: boolean
}>(({ theme, disabled }) => ({
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? theme.palette.action.disabledOpacity : 1,
}))

interface IDeletableTableRowProps extends TableRowProps {
  onDelete: () => void
  disabled?: boolean
}

const DeletableTableRow: React.FC<IDeletableTableRowProps> = ({
  onDelete,
  disabled,
  ...props
}) => {
  const [isHovered, setIsHovered] = React.useState(false)
  const handleHover = (value: boolean) => () => {
    setIsHovered(value)
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onDelete()
  }

  const { isMobile } = useWindowSize()

  const showDeleteButton = isMobile || isHovered

  return (
    <CandidateTableRow
      onMouseEnter={isMobile ? undefined : handleHover(true)}
      onMouseLeave={isMobile ? undefined : handleHover(false)}
      disabled={disabled}
      {...props}
    >
      {props.children}

      <TableCell
        sx={{
          p: (theme) => theme.spacing(0, 1),
          width: '60px',
        }}
        align="right"
      >
        <Tooltip
          title="Ta bort"
          sx={{ visibility: showDeleteButton ? 'visible' : 'hidden' }}
        >
          <IconButton
            onClick={handleDelete}
            aria-label="delete"
            data-testid="table-row-delete-button"
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </CandidateTableRow>
  )
}

export default DeletableTableRow
