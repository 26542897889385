import {
  AdditionalInformation,
  IDeepLocation,
  IJobTitle,
} from '@local/Types/MatchingApi.types'
import { ICvPdf } from '@local/Profile/api/ProfileApi'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react/'

interface IGetProfileParams {
  profileId: string
  listId: string
}

export interface ExternalProfileApiResponse {
  id: string
  cvPdf: ICvPdf
  jobTitles: IJobTitle[]
  personalInformation: ExternalPersonalInformation
  locationInformations: IDeepLocation[]
  additionalInformation: AdditionalInformation
}

interface ISocialMedia {
  type: string
  url: string
}

interface ExternalPersonalInformation {
  firstName: string
  lastName: string
  email: string
  mobilePhone: string
  socialMedia: ISocialMedia[]
}

export const externalProfileApi = createApi({
  reducerPath: 'externalProfileApi',
  baseQuery: trrFetchBaseQuery(true),
  endpoints: (builder) => ({
    getExternalProfile: builder.query<
      ExternalProfileApiResponse,
      IGetProfileParams
    >({
      query: ({ profileId, listId }) =>
        `/CandidateLists/${listId}/profiles/${profileId}`,
    }),
  }),
})

export const { useGetExternalProfileQuery } = externalProfileApi
