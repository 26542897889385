import React, { useCallback, useEffect } from 'react'
import { PeopleAlt } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import { IMatch } from '@local/Types/MatchingApi.types'
import { useHistory } from 'react-router-dom'
import {
  addCandidate,
  addCandidates,
  appRoutes,
  removeCandidate,
  removeCandidates,
} from '@local/Utils/Helpers'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@local/Store/configureStore'
import { CandidateTableHead } from '@local/FindCandidate/Components/CandidateList/CandidateTableHead'
import { MultipleTextItems } from '@local/FindCandidate/Components/CandidateList/MultipleTextItems'
import { useSearchQueryParams } from '@local/FindCandidate/Hooks/useSearchQueryParams'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useWindowSize } from '@local/Utils/Hooks/useWindowSize'

interface CandidateListProps {
  candidates?: IMatch[]
  totalCount?: number
  rowsPerPageOptions: number[]
  rowsPerPage?: number
}

const ConsentGivenIcon = styled(PeopleAlt)({
  width: 15,
  height: 15,
})

const EllipsisText = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '70vw',
})

const StyledTablePagination = styled(TablePagination, {
  shouldForwardProp: (prop) => prop !== '$ismobile',
})<{
  $ismobile: number
  component: React.ElementType
}>(({ $ismobile }) => ({
  '.MuiTablePagination-toolbar': $ismobile && {
    padding: 0,
    justifyContent: 'space-between',
  },
  '.MuiTablePagination-actions': $ismobile && {
    marginLeft: '0 !important',
  },
  '.MuiTablePagination-spacer': $ismobile && {
    display: 'none',
  },
  '.MuiTablePagination-displayedRows': $ismobile && {
    flex: 1,
    textAlign: 'end',
  },
  '.MuiTablePagination-input': {
    padding: '0 !important',
    ...($ismobile && {
      marginLeft: '0 !important',
      marginRight: '0 !important',
    }),
  },
}))

export const CandidateList = ({
  candidates,
  totalCount,
  rowsPerPageOptions,
  rowsPerPage,
}: CandidateListProps) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { push, location } = useHistory()
  const { formattedSearchParams, setSearchParamField } = useSearchQueryParams()
  const dispatch = useDispatch()
  const selectedCandidates = useSelector(
    (state: RootState) => state.candidateListSlice.selectedCandidates
  )
  const { consentGivenTooltip } = usePickEpiContent().sokKandidatNy.candidate

  const baseUrl = '/medarbetare/matcha-kandidater/'

  const visibleCandidates = candidates?.filter((candidate) =>
    selectedCandidates.some(
      (selectedCandidate) => selectedCandidate.id === candidate.id
    )
  ).length

  useEffect(() => {
    const prevScroll = sessionStorage.getItem('trr-sokkandidat-saved-scroll')

    if (prevScroll) {
      sessionStorage.removeItem('trr-sokkandidat-saved-scroll')
      window.scrollTo({ top: parseInt(prevScroll), behavior: 'smooth' })
    }
  }, [])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked
      ? dispatch(addCandidates(candidates))
      : dispatch(removeCandidates(candidates))
  }

  const goToProfile = (id: string) => {
    sessionStorage.setItem(
      'trr-sokkandidat-saved-scroll',
      window.scrollY.toString()
    )

    const searchParams = location.search
      ? `${location.search}&id=${id}`
      : `?id=${id}`
    push(`${baseUrl}${appRoutes.profil}${searchParams}`)
  }

  const handleSelectCandidate = (e: React.MouseEvent, match: IMatch) => {
    e.stopPropagation()
    if (selectedCandidates.some((c) => c.id === match.id)) {
      dispatch(removeCandidate(match))
    } else {
      dispatch(addCandidate(match))
    }
  }

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setSearchParamField((newPage + 1).toString(), 'page')
    },
    [setSearchParamField]
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchParamField(event.target.value, 'pageSize')
    },
    [setSearchParamField]
  )

  const { isDesktop, isMobile } = useWindowSize()

  const currentPage = formattedSearchParams.page
    ? formattedSearchParams.page - 1
    : 0

  return (
    <Box>
      <TableContainer sx={{ overflowX: 'visible' }}>
        <Table aria-labelledby="tableTitle" size="medium">
          <CandidateTableHead
            numSelected={visibleCandidates}
            totalNumSelected={selectedCandidates.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={candidates?.length}
            totalCount={totalCount}
            paginationComponent={
              <StyledTablePagination
                rowsPerPageOptions={[]}
                count={totalCount}
                component="div"
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                $ismobile={isMobile ? 1 : 0}
              />
            }
          />
          <TableBody>
            {candidates?.map((row, index) => {
              const isItemSelected = selectedCandidates
                .map((c) => c.id)
                .includes(row.id)
              const labelId = `custom-table-checkbox-${index}`

              return (
                <TableRow
                  hover
                  onClick={() => goToProfile(row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                  data-testid="matching-list-item"
                >
                  <TableCell
                    padding="none"
                    onClick={(e) => {
                      handleSelectCandidate(e, row)
                    }}
                  >
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onClick={(e) => handleSelectCandidate(e, row)}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      data-testid="client-add-to-list"
                    />
                  </TableCell>
                  {isDesktop ? (
                    <>
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="normal"
                        sx={{
                          width: '20rem',
                          maxWidth: '20rem',
                          borderStyle: 'border-box',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <EllipsisText variant="body1">
                          {`${row.personalInformation.firstName} ${row.personalInformation.lastName}`}
                        </EllipsisText>
                      </TableCell>
                      <TableCell scope="row" padding="normal">
                        <MultipleTextItems
                          items={row.jobTitles.map((job) => job.name)}
                          icon={
                            row.consent ? (
                              <Tooltip title={consentGivenTooltip}>
                                <ConsentGivenIcon />
                              </Tooltip>
                            ) : null
                          }
                        />
                      </TableCell>
                    </>
                  ) : (
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      colSpan={2}
                      sx={{ padding: '1rem 0 1rem 1rem' }}
                    >
                      <EllipsisText variant="body1">{`${row.personalInformation.firstName} ${row.personalInformation.lastName}`}</EllipsisText>
                      <MultipleTextItems
                        items={row.jobTitles.map((job) => job.name)}
                        icon={row.consent ? <ConsentGivenIcon /> : null}
                      />
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledTablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        count={totalCount}
        component="div"
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        $ismobile={isMobile ? 1 : 0}
      />
    </Box>
  )
}
