import { INewMatchSearchApiRequest } from '@local/Types/MatchingApi.types'
import { useEffect } from 'react'
import {
  NumberParam,
  useQueryParams,
  withDefault,
  BooleanParam,
  DelimitedArrayParam,
  QueryParamConfig,
  StringParam,
} from 'use-query-params'

export interface ISearchParamsComparison {
  searchTerms: string[]
  locations: string[]
  jobTitles: string[]
  occupationGroups: string[]
  publishingPermissions: string[]
  formsOfEmployment: string[]
  extentsOfEmployment: string[]
  otherPreferences: string[]
  page: number
  pageSize: number
  currentFacet: string
  includeCvContent: boolean
}
interface ISearchParams {
  searchTerms: QueryParamConfig<string[], string[]>
  locations: QueryParamConfig<string[], string[]>
  jobTitles: QueryParamConfig<string[], string[]>
  occupationGroups: QueryParamConfig<string[], string[]>
  publishingPermissions: QueryParamConfig<string[], string[]>
  formsOfEmployment: QueryParamConfig<string[], string[]>
  extentsOfEmployment: QueryParamConfig<string[], string[]>
  otherPreferences: QueryParamConfig<string[], string[]>
  page: QueryParamConfig<number, number>
  pageSize: QueryParamConfig<number, number>
  currentFacet: QueryParamConfig<string, string>
  includeCvContent: QueryParamConfig<boolean, boolean>
}

type ArraySearchParams = Omit<
  ISearchParams,
  'page' | 'pageSize' | 'currentFacet' | 'includeCvContent'
>

type TextFieldSearchParam = Pick<ISearchParams, 'searchTerms' | 'locations'>

const defaultParams = {
  searchTerms: withDefault(DelimitedArrayParam, []),
  locations: withDefault(DelimitedArrayParam, []),
  jobTitles: withDefault(DelimitedArrayParam, []),
  occupationGroups: withDefault(DelimitedArrayParam, []),
  publishingPermissions: withDefault(DelimitedArrayParam, []),
  formsOfEmployment: withDefault(DelimitedArrayParam, []),
  extentsOfEmployment: withDefault(DelimitedArrayParam, []),
  otherPreferences: withDefault(DelimitedArrayParam, []),
  page: withDefault(NumberParam, 1),
  pageSize: withDefault(NumberParam, 20),
  currentFacet: withDefault(StringParam, ''),
  includeCvContent: withDefault(BooleanParam, false),
}

export const defaultParamsComparison: ISearchParamsComparison = {
  searchTerms: [],
  locations: [],
  jobTitles: [],
  occupationGroups: [],
  publishingPermissions: [],
  formsOfEmployment: [],
  extentsOfEmployment: [],
  otherPreferences: [],
  page: 1,
  pageSize: 20,
  currentFacet: '',
  includeCvContent: false,
}

export const useSearchQueryParams = () => {
  const [searchParams, setSearchParams] = useQueryParams(defaultParams, {
    updateType: 'replaceIn',
    removeDefaultsFromUrl: true,
  })

  useEffect(() => {
    const queryParamsJson = JSON.stringify({
      ...searchParams,
      currentFacet: '',
    })
    sessionStorage.setItem('sokKandidat-savedParams', queryParamsJson)
  }, [searchParams])

  const formattedSearchParams: INewMatchSearchApiRequest = {
    jobTitles: [],
    jobTitlesFreeText: searchParams.searchTerms,
    locations: searchParams.locations.map((location: string) => ({
      name: location.split(',')[0],
      type: location.split(',')[1],
    })),
    filters: {
      extentsOfEmployment: searchParams.extentsOfEmployment,
      formsOfEmployment: searchParams.formsOfEmployment,
      otherPreferences: searchParams.otherPreferences,
      occupationGroups: searchParams.occupationGroups,
      jobTitles: searchParams.jobTitles,
      publishingPermissions: searchParams.publishingPermissions,
    },
    onlyPublishedCandidates: true,
    page: searchParams.page,
    limit: searchParams.pageSize,
    currentFacet: searchParams.currentFacet,
    includeCvContent: searchParams.includeCvContent,
  }

  const evaluateCurrentFacet = (
    value: string,
    fieldName: keyof ArraySearchParams
  ) => {
    if (searchParams[fieldName].includes(value)) {
      return searchParams[fieldName]?.length === 1 ? '' : fieldName
    }

    return fieldName
  }

  const setSearchParamField = (
    value: string,
    fieldName: keyof ISearchParams
  ) => {
    if (typeof searchParams[fieldName] === 'number') {
      if (fieldName === 'page') {
        setSearchParams({
          [fieldName]: parseInt(value),
        })
      } else {
        setSearchParams({
          [fieldName]: parseInt(value),
          page: 1,
        })
      }

      return
    }

    if (typeof searchParams[fieldName] === 'boolean') {
      setSearchParams({
        [fieldName]: value,
        page: 1,
      })

      return
    }

    const arrayFields = fieldName as keyof ArraySearchParams
    const currentFacet = evaluateCurrentFacet(value, arrayFields)

    if (!searchParams[arrayFields]?.includes(value)) {
      setSearchParams({
        [arrayFields]: searchParams[arrayFields]?.length
          ? [...searchParams[arrayFields], value]
          : [value],
        currentFacet: currentFacet,
        page: 1,
      })
    } else {
      setSearchParams({
        [arrayFields]: searchParams[arrayFields].filter(
          (facet: string) => facet !== value
        ),
        currentFacet: currentFacet,
        page: 1,
      })
    }
  }

  const setTextSearchField = (
    value: string[],
    fieldName: keyof TextFieldSearchParam
  ) => {
    if (fieldName === 'searchTerms') {
      setSearchParams({
        searchTerms: value,
        locations: searchParams.locations,
        page: 1,
        pageSize: searchParams.pageSize,
        includeCvContent: searchParams.includeCvContent,
        currentFacet: '',
      })
    } else if (fieldName === 'locations') {
      setSearchParams({
        searchTerms: searchParams.searchTerms,
        locations: value,
        page: 1,
        pageSize: searchParams.pageSize,
        includeCvContent: searchParams.includeCvContent,
        currentFacet: '',
      })
    }
  }

  const clearAllSearchParams = () => {
    setSearchParams({
      extentsOfEmployment: [],
      formsOfEmployment: [],
      otherPreferences: [],
      occupationGroups: [],
      jobTitles: [],
      publishingPermissions: [],
    })
  }

  return {
    searchParams,
    setSearchParams,
    setSearchParamField,
    setTextSearchField,
    clearAllSearchParams,
    formattedSearchParams,
  }
}
