import {
  AdditionalInformation,
  IDeepLocation,
  IJobTitle,
} from '@local/Types/MatchingApi.types'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react/'

export interface IAdvisor {
  id: string
  firstName: string
  lastName: string
}

export interface ICvPdf {
  id: string
  name: string
}
export interface ApiResultClientMatchingData {
  id: string
  positions: string[]
  jobTitles: IJobTitle[]
  isSavedAfterMigration: boolean
  locationInformations: IDeepLocation[]
  additionalInformation: AdditionalInformation
  isSearchAble: boolean
  consent: boolean
  active: boolean
  cvPdf: ICvPdf
  advisor: IAdvisor
}

export interface ApiResultClientProfileData {
  id: string
  firstName: string
  lastName: string
  preferredFirstName: string
  socialSecurityNumber: number
  email: string
  mdid: string
  address: {
    streetAddress: string
    city: string
    postalCode: string
  }
  mobilePhone: string
  healthConsent: boolean
  peopleRegistrationStatus: string
  socialMedia: { type: string; url: string }[]
}

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    getCandidateMatchingData: builder.query<
      ApiResultClientMatchingData,
      string
    >({
      query: (id) => `/client-match/profiles/${id}`,
    }),
    getCandidateUserData: builder.query<ApiResultClientProfileData, string>({
      query: (id) => `/userprofile/${id}/klient`,
    }),
  }),
})

export const {
  useGetCandidateMatchingDataQuery,
  useGetCandidateUserDataQuery,
} = profileApi
