import { SokKandidatContent } from '@local/Types'

export const fallbackContent: SokKandidatContent = {
  sokKandidatNy: {
    skapaUrval: {
      title: 'skapaUrval.title',
    },
    yrkesrollSearch: {
      label: 'yrkesrollSearch.label',
      placeholder: 'yrkesrollSearch.placeholder',
    },
    arbetsortSearch: {
      label: 'arbetsortSearch.label',
      placeholder: 'arbetsortSearch.placeholder',
    },
    searchFiltering: {
      allFiltersOptionTitle: 'allFiltersOptionTitle',
      jobTitlesFilterTitle: 'jobTitlesFilterTitle',
      occupationGroupsFilterTitle: 'occupationGroupsFilterTitle',
      publishingPermissionsFilterTitle: 'publishingPermissionsFilterTitle',
      otherFiltersFilterTitle: 'otherFiltersFilterTitle',
      extentsOfEmploymentFilterOption: 'extentsOfEmploymentFilterOption',
      formsOfEmploymentFilterOption: 'formsOfEmploymentFilterOption',
      otherPreferencesFilterOption: 'otherPreferencesFilterOption',
      noFiltersText: 'noFiltersText',
      emptyStateTitle: 'emptyStateTitle',
      emptyStateText: 'emptyStateText',
      totalHitsText: 'totalHitsText',
      selectionText: 'selectionText',
      selectionShortText: 'selectionShortText',
    },
    searchFilteringFacets: {
      partTime: 'partTime',
      fullTime: 'fullTime',
      employee: 'employee',
      consultant: 'consultant',
      temporaryEmployee: 'temporaryEmployee',
      freelance: 'freelance',
      onSite: 'onSite',
      remoteWork: 'remoteWork',
      hybrid: 'hybrid',
      doNotPublish: 'doNotPublish',
      showMyProfile: 'showMyProfile',
    },
    addToCandidateList: {
      addToList: 'addToList',
      addToNewList: 'addToNewList',
      dialogTitle: 'dialogTitle',
      dialogInputLabel: 'dialogInputLabel',
      dialogInputPlaceholder: 'dialogInputPlaceholder',
      dialogInputValidationText: 'dialogInputValidationText',
      dialogDescription: 'dialogDescription',
      dialogSuggestionDescription: 'dialogSuggestionDescription',
      dialogCancelButton: 'dialogCancelButton',
      dialogCreateButton: 'dialogCreateButton',
    },
    inkluderaCv: {
      checkboxLabel: 'inkluderaCv.checkboxLabel',
      infoDialogTitle: 'inkluderaCv.infoDialogTitle',
    },
    inkluderaCvDialogContent: {
      mainBody: '<p>inkluderaCvDialogContent.mainBody</p>',
    },
    candidate: {
      consentGivenTooltip: 'candidate.consentGivenTooltip',
    },
  },
  minaListor: {
    removeCandidateListDialog: {
      bodyActiveBlock1: 'removeCandidateListDialog.bodyActiveBlock1',
      bodyNoCandidates: 'removeCandidateListDialog.bodyNoCandidates',
      bodyActiveBlock2: 'removeCandidateListDialog.bodyActiveBlock2',
      titleActive: 'removeCandidateListDialog.titleActive',
      title: 'removeCandidateListDialog.title',
      body: 'removeCandidateListDialog.body',
      actionButton: 'removeCandidateListDialog.actionButton',
      disableButton: 'Inaktivera lista',
      cancelButton: 'removeCandidateListDialog.cancelButton',
    },
    emptyResult: {
      title: 'emptyResult.title',
      body: 'emptyResult.body',
    },
    listOfCandidateLists: {
      activeText: 'activeText',
      activeExpiringSoonText: 'activeExpiringSoonText',
      inactiveText: 'inactiveText',
    },
  },
  candidateList: {
    removeCandidateDialog: {
      title: 'removeCandidateDialog.title',
      body: 'removeCandidateDialog.body',
      actionButton: 'removeCandidateDialog.actionButton',
      cancelButton: 'removeCandidateDialog.cancelButton',
    },
    changeListnameDialog: {
      title: 'changeListnameDialog.title',
      body: 'changeListnameDialog.body',
      errorCharLimit: 'changeListnameDialog.errorCharLimit',
      label: 'changeListnameDialog.label',
      placeholder: 'changeListnameDialog.placeholder',
      cancelButton: 'changeListnameDialog.cancelButton',
      updateButton: 'changeListnameDialog.updateButton',
    },
    externalListDialog: {
      block1: 'externalListDialog.block1',
      block2: 'externalListDialog.block2',
      block3: 'externalListDialog.block3',
      deactivateText1: 'externalListDialog.deactivateText1',
      deactivateText2: 'externalListDialog.deactivateText2',
      createButton: 'externalListDialog.createButton',
      deactivateButton: 'externalListDialog.deactivateButton',
      titleCreate: 'externalListDialog.titleCreate',
      titleDeactivate: 'externalListDialog.titleDeactivate',
      cancelButton: 'externalListDialog.cancelButton',
    },
    createEmailDialog: {
      block1: 'createEmailDialog.block1',
      block2: 'createEmailDialog.block2',
      title: 'createEmailDialog.title',
      error: 'createEmailDialog.error',
      label: 'createEmailDialog.label',
      lastCreated: 'createEmailDialog.lastCreated',
      primaryButton: 'createEmailDialog.primaryButton',
      cancelButton: 'createEmailDialog.cancelButton',
    },
    removeCandidateListDialog: {
      bodyActiveBlock1: 'removeCandidateListDialog.bodyActiveBlock1',
      bodyNoCandidates: 'removeCandidateListDialog.bodyNoCandidates',
      bodyActiveBlock2: 'removeCandidateListDialog.bodyActiveBlock2',
      titleActive: 'removeCandidateListDialog.titleActive',
      title: 'removeCandidateListDialog.title',
      body: 'removeCandidateListDialog.body',
      actionButton: 'removeCandidateListDialog.actionButton',
      disableButton: 'Inaktivera lista',
      cancelButton: 'removeCandidateListDialog.cancelButton',
    },
    emptyResult: {
      title: 'emptyResult.title',
      body: 'emptyResult.body',
    },
    metadata: {
      modified: 'metadata.modified',
    },
    actions: {
      removeList: 'actions.removeList',
      changeListNameTooltip: 'actions.changeListNameTooltip',
      inactiveCandidatesInfo: 'actions.inactiveCandidatesInfo',
    },
    externalLink: {
      link: 'externalLink.link',
      utmSource: '', // EMPTY STRING, DO NOT CHANGE
      utmMedium: '', // EMPTY STRING, DO NOT CHANGE
      utmCampaign: '', // EMPTY STRING, DO NOT CHANGE
      utmContent: '', // EMPTY STRING, DO NOT CHANGE
      utmTerm: '', // EMPTY STRING, DO NOT CHANGE
      contactTooltip: 'externalLink.contactTooltip',
      copyTooltip: 'externalLink.copyTooltip',
      createTooltip: 'externalLink.createTooltip',
      deactivateButton: 'externalLink.deactivateButton',
      copiedText: 'externalLink.copiedText',
      activeText: 'externalLink.activeText',
      activeExpiringSoonText: 'externalLink.activeExpiringSoonText',
      inactiveText: 'externalLink.inactiveText',
    },
    candidate: {
      consentGivenTooltip: 'candidate.consentGivenTooltip',
    },
  },
  profil: {
    consentSection: {
      title: 'consentSection.title',
      body: 'consentSection.body',
    },
    jobtitleSection: {
      title: 'jobtitleSection.title',
    },
    locationSection: {
      title: 'locationSection.title',
      otherPreferencesTitle: 'locationSection.otherPreferencesTitle',
      locationsSwedenTitle: 'locationSection.locationsSwedenTitle',
      locationsAbroadTitle: 'locationSection.locationsAbroadTitle',
      Hybrid: 'locationSection.Hybrid',
      OnSite: 'locationSection.OnSite',
      RemoteWork: 'locationSection.RemoteWork',
    },
    workTypeSection: {
      title: 'workTypeSection.title',
      formsOfEmploymentTitle: 'workTypeSection.formsOfEmploymentTitle',
      extentsOfEmploymentTitle: 'workTypeSection.extentsOfEmploymentTitle',
      Consultant: 'workTypeSection.Consultant',
      Employee: 'workTypeSection.Employee',
      TemporaryEmployee: 'workTypeSection.TemporaryEmployee',
      Freelance: 'workTypeSection.Freelance',
      FullTime: 'workTypeSection.FullTime',
      PartTime: 'workTypeSection.PartTime',
    },
    callToAction: {
      contactInfoTitle: 'callToAction.contactInfoTitle',
      contactInfoEmailButton: 'callToAction.contactInfoEmailButton',
      resumeInfoTitle: 'callToAction.resumeInfoTitle',
      resumeInfoLinkedin: 'callToAction.resumeInfoLinkedin',
      resumeInfoDownloadCv: 'callToAction.resumeInfoDownloadCv',
      resumeInfoPreviewCv: 'callToAction.resumeInfoPreviewCv',
      advisorTitle: 'callToAction.advisorTitle',
      cvErrorText: 'callToAction.cvErrorText',
    },
    addToCandidateList: {
      addToList: 'addToCandidateList.addToList',
      addToNewList: 'addToCandidateList.addToNewList',
      dialogTitle: 'addToCandidateList.dialogTitle',
      dialogInputLabel: 'addToCandidateList.dialogInputLabel',
      dialogInputPlaceholder: 'addToCandidateList.dialogInputPlaceholder',
      dialogInputValidationText: 'addToCandidateList.dialogInputValidationText',
      dialogDescription: 'addToCandidateList.dialogDescription',
      dialogSuggestionDescription:
        'addToCandidateList.dialogSuggestionDescription',
      dialogCancelButton: 'addToCandidateList.dialogCancelButton',
      dialogCreateButton: 'addToCandidateList.dialogCreateButton',
    },
  },
  externalList: {
    titleAndBody: {
      body: 'titleAndBody.body',
      modified: 'titleAndBody.modified',
      profileLink: 'titleAndBody.profileLink',
    },
    errorContent: {
      errorTitle: 'errorContent.errorTitle',
      errorBody: 'errorContent.errorBody',
      emptyResultTitle: 'errorContent.emptyResultTitle',
      emptyResultBody: 'errorContent.emptyResultBody',
    },
    status: {
      activeText: 'status.activeText',
      activeExpiringSoonText: 'status.activeExpiringSoonText',
      inactiveText: 'status.inactiveText',
    },
  },
  externalProfile: {
    jobtitleSection: {
      title: 'jobtitleSection.title',
    },
    locationSection: {
      title: 'locationSection.title',
      otherPreferencesTitle: 'locationSection.otherPreferencesTitle',
      locationsSwedenTitle: 'locationSection.locationsSwedenTitle',
      locationsAbroadTitle: 'locationSection.locationsAbroadTitle',
      Hybrid: 'locationSection.Hybrid',
      OnSite: 'locationSection.OnSite',
      RemoteWork: 'locationSection.RemoteWork',
    },
    workTypeSection: {
      title: 'workTypeSection.title',
      formsOfEmploymentTitle: 'workTypeSection.formsOfEmploymentTitle',
      extentsOfEmploymentTitle: 'workTypeSection.extentsOfEmploymentTitle',
      Consultant: 'workTypeSection.Consultant',
      Employee: 'workTypeSection.Employee',
      TemporaryEmployee: 'workTypeSection.TemporaryEmployee',
      Freelance: 'workTypeSection.Freelance',
      FullTime: 'workTypeSection.FullTime',
      PartTime: 'workTypeSection.PartTime',
    },
    callToAction: {
      contactInfoTitle: 'callToAction.contactInfoTitle',
      contactInfoEmailButton: 'callToAction.contactInfoEmailButton',
      resumeInfoTitle: 'callToAction.resumeInfoTitle',
      resumeInfoLinkedin: 'callToAction.resumeInfoLinkedin',
      resumeInfoDownloadCv: 'callToAction.resumeInfoDownloadCv',
      resumeInfoPreviewCv: 'callToAction.resumeInfoPreviewCv',
      advisorTitle: 'callToAction.advisorTitle',
      cvErrorText: 'callToAction.cvErrorText',
    },
  },
}
