import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { replaceEpiVariables } from '@local/Utils/Helpers'

interface ICreateGroupEmailDialog {
  listName: string
  mostRecentDraft?: string
  onToggle: () => void
  onSubmit: (subject: string) => void
}

const CreateGroupEmailDialog: React.FunctionComponent<
  ICreateGroupEmailDialog
> = ({ listName, mostRecentDraft, onToggle, onSubmit }) => {
  const [input, setInput] = useState('')
  const [error, setError] = useState(false)
  const {
    block1,
    block2,
    title,
    error: inputError,
    label,
    lastCreated,
    primaryButton,
    cancelButton,
  } = usePickEpiContent().candidateList.createEmailDialog

  const isValidInput = input.length > 2

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    error && setError(false)
    setInput(event.target.value)
  }

  const handleSubmit = () => {
    if (isValidInput) {
      setInput('')
      onSubmit(input)
    } else {
      setError(true)
    }
  }

  const handleBlur = () => {
    if (!isValidInput) {
      setError(true)
    }
  }

  return (
    <Dialog open onClose={onToggle}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" mb={2}>
          {replaceEpiVariables(block1, {
            listName,
          })}
        </Typography>

        <TextField
          label={label}
          onChange={handleOnChange}
          onBlur={handleBlur}
          error={error}
          helperText={error ? inputError : ''}
          value={input}
          sx={{ mb: 2 }}
          fullWidth
          inputProps={{
            maxLength: 215,
          }}
        />
        <Typography variant="body1" gutterBottom={!!mostRecentDraft}>
          {block2}
        </Typography>
        {!!mostRecentDraft && (
          <Typography variant="body1">
            {replaceEpiVariables(lastCreated, {
              date: mostRecentDraft.substring(0, 10),
            })}
          </Typography>
        )}
      </DialogContent>
      <DialogContent>
        <DialogActions>
          <Button onClick={onToggle} variant="text">
            {cancelButton}
          </Button>
          <Button onClick={handleSubmit}>{primaryButton}</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default CreateGroupEmailDialog
