import {
  IJobFiltersRequest,
  INewMatchSearchApiRequest,
} from '@local/Types/MatchingApi.types'

export const getActiveFilterCount = (
  formattedSearchParams: INewMatchSearchApiRequest
): number =>
  Object.keys(formattedSearchParams?.filters).reduce(
    (acc, key) =>
      acc +
      formattedSearchParams?.filters[key as keyof IJobFiltersRequest]?.length,
    0
  )
