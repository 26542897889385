/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import {
  IJobFiltersRequest,
  IJobFiltersResponse,
} from '@local/Types/MatchingApi.types'
import { useSearchQueryParams } from '@local/FindCandidate/Hooks/useSearchQueryParams'
import { DesktopFilter } from '@local/FindCandidate/Components/JobFilter/DesktopFilter'
import { MobileFilter } from '@local/FindCandidate/Components/JobFilter/MobileFilter'
import { removeAllCandidates } from '@local/Utils/Helpers'
import { useDispatch } from 'react-redux'
import { useWindowSize } from '@local/Utils/Hooks/useWindowSize'

interface JobFilterProps {
  options: IJobFiltersResponse
}

export const JobFilter = forwardRef<HTMLDivElement, JobFilterProps>(
  ({ options }: JobFilterProps, ref) => {
    const { isDesktop } = useWindowSize()
    const { setSearchParamField } = useSearchQueryParams()
    const dispatch = useDispatch()

    const handleFilterChange = (
      filterName: string,
      group: keyof IJobFiltersRequest
    ) => {
      setSearchParamField(filterName, group)
      dispatch(removeAllCandidates())
    }

    return isDesktop ? (
      <DesktopFilter
        options={options}
        ref={ref}
        filterChange={handleFilterChange}
      />
    ) : (
      <MobileFilter options={options} filterChange={handleFilterChange} />
    )
  }
)
