/* eslint-disable react/display-name */

import { FilterBar } from '@local/FindCandidate/Components/FilterBar/FilterBar'
import { FilterOption } from '@local/FindCandidate/Components/FilterBar/FilterOption'
import { FilterOptionGroup } from '@local/FindCandidate/Components/FilterBar/FilterOptionGroup'
import { getActiveFilterCount } from '@local/FindCandidate/Components/Utils/filterHelper'
import { useSearchQueryParams } from '@local/FindCandidate/Hooks/useSearchQueryParams'
import {
  IJobFiltersRequest,
  IJobFiltersResponse,
} from '@local/Types/MatchingApi.types'
import { mapFacetsToOptions } from '@local/Utils/Helpers/typeHelper'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { Button } from '@mui/material'
import React, { forwardRef } from 'react'

interface DesktopFilterProps {
  options: IJobFiltersResponse
  filterChange: (filterName: string, group: keyof IJobFiltersRequest) => void
}

export const DesktopFilter = forwardRef<HTMLDivElement, DesktopFilterProps>(
  ({ options, filterChange }: DesktopFilterProps, ref) => {
    const { searchFiltering, searchFilteringFacets } =
      usePickEpiContent().sokKandidatNy
    const { formattedSearchParams, setSearchParams, clearAllSearchParams } =
      useSearchQueryParams()

    const resetFilterGroups = (listOfGroups: (keyof IJobFiltersRequest)[]) => {
      const resetObj = listOfGroups.reduce((acc, group) => {
        acc[group] = []
        return acc
      }, {} as Partial<IJobFiltersRequest>)
      setSearchParams({ ...resetObj, currentFacet: '', page: 1 })
    }

    const showClearButton = getActiveFilterCount(formattedSearchParams) > 0

    return (
      <FilterBar ref={ref}>
        <FilterOptionGroup
          label={searchFiltering.occupationGroupsFilterTitle}
          totalCount={options?.occupationGroups?.length}
          selectCount={formattedSearchParams?.filters?.occupationGroups?.length}
          onResetFilters={() => resetFilterGroups(['occupationGroups'])}
        >
          <FilterOption
            subtitle=""
            selectedFilters={formattedSearchParams?.filters?.occupationGroups}
            options={mapFacetsToOptions(
              options?.occupationGroups,
              searchFilteringFacets
            )}
            onChangeFilter={(filterName: string) =>
              filterChange(filterName, 'occupationGroups')
            }
          />
        </FilterOptionGroup>
        <FilterOptionGroup
          label={searchFiltering.jobTitlesFilterTitle}
          totalCount={options?.jobTitles?.length}
          selectCount={formattedSearchParams?.filters?.jobTitles?.length}
          onResetFilters={() => resetFilterGroups(['jobTitles'])}
        >
          <FilterOption
            subtitle=""
            selectedFilters={formattedSearchParams?.filters?.jobTitles}
            options={mapFacetsToOptions(
              options?.jobTitles,
              searchFilteringFacets
            )}
            onChangeFilter={(filterName: string) =>
              filterChange(filterName, 'jobTitles')
            }
          />
        </FilterOptionGroup>
        <FilterOptionGroup
          label={searchFiltering.publishingPermissionsFilterTitle}
          totalCount={options?.publishingPermissions?.length}
          selectCount={
            formattedSearchParams?.filters?.publishingPermissions?.length
          }
          onResetFilters={() => resetFilterGroups(['publishingPermissions'])}
        >
          <FilterOption
            subtitle=""
            selectedFilters={
              formattedSearchParams?.filters?.publishingPermissions
            }
            options={mapFacetsToOptions(
              options?.publishingPermissions,
              searchFilteringFacets
            )}
            onChangeFilter={(filterName: string) =>
              filterChange(filterName, 'publishingPermissions')
            }
          />
        </FilterOptionGroup>
        <FilterOptionGroup
          label={searchFiltering.otherFiltersFilterTitle}
          totalCount={
            options?.extentsOfEmployment?.length +
            options?.formsOfEmployment?.length +
            options?.otherPreferences?.length
          }
          selectCount={
            formattedSearchParams?.filters?.extentsOfEmployment?.length +
            formattedSearchParams?.filters?.formsOfEmployment?.length +
            formattedSearchParams?.filters?.otherPreferences?.length
          }
          onResetFilters={() =>
            resetFilterGroups([
              'extentsOfEmployment',
              'formsOfEmployment',
              'otherPreferences',
            ])
          }
        >
          <FilterOption
            subtitle={searchFiltering.extentsOfEmploymentFilterOption}
            selectedFilters={
              formattedSearchParams?.filters?.extentsOfEmployment
            }
            options={mapFacetsToOptions(
              options?.extentsOfEmployment,
              searchFilteringFacets
            )}
            onChangeFilter={(filterName: string) =>
              filterChange(filterName, 'extentsOfEmployment')
            }
          />
          <FilterOption
            subtitle={searchFiltering.formsOfEmploymentFilterOption}
            selectedFilters={formattedSearchParams?.filters?.formsOfEmployment}
            options={mapFacetsToOptions(
              options?.formsOfEmployment,
              searchFilteringFacets
            )}
            onChangeFilter={(filterName: string) =>
              filterChange(filterName, 'formsOfEmployment')
            }
          />
          <FilterOption
            subtitle={searchFiltering.otherPreferencesFilterOption}
            selectedFilters={formattedSearchParams?.filters?.otherPreferences}
            options={mapFacetsToOptions(
              options?.otherPreferences,
              searchFilteringFacets
            )}
            onChangeFilter={(filterName: string) =>
              filterChange(filterName, 'otherPreferences')
            }
          />
        </FilterOptionGroup>
        {showClearButton && (
          <Button
            size="small"
            variant="text"
            onClick={clearAllSearchParams}
            data-testid={'clearFiltersButton'}
          >
            Rensa allt
          </Button>
        )}
      </FilterBar>
    )
  }
)
