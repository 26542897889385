import {
  IDeepLocation,
  IFacet,
  IJobFiltersRequest,
  IJobFiltersResponse,
  IMatchSearchApiRequest,
  IMatchSearchApiResponse,
} from '@local/Types/MatchingApi.types'
import { convertToEnglishLocationTypesCapitalStart } from '@local/Utils/Helpers'
import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'
import { clone, uniq } from 'ramda'

type FetchFunction = (
  arg: string | FetchArgs
) => MaybePromise<
  QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
>

export const getMatchesMapper = async (
  req: IMatchSearchApiRequest,
  fetchFunc: FetchFunction
): Promise<IMatchSearchApiResponse> => {
  const locationsInEnglish = req.locations.map((loc) => ({
    ...loc,
    type: convertToEnglishLocationTypesCapitalStart(loc.type),
  }))
  const locationsToLookup: string[] = uniq(req.locations.map((loc) => loc.name))
  const deepLocationData = async () => {
    const locationsFound: IDeepLocation[] = []
    for (const loc of locationsToLookup) {
      const res = await fetchFunc(`/referencedata/locations/suggest/${loc}`)
      const data = res.data as IDeepLocation[]
      locationsFound.push(...data)
    }
    return locationsFound
  }
  const deepLocations = await deepLocationData()
  const filterDeepLocations = deepLocations.filter((deepLoc) =>
    locationsInEnglish.find(
      (loc) => loc.type === deepLoc.type && loc.name === deepLoc.name
    )
  )

  const dataWithDeepLocations = await fetchFunc({
    url: `/client-match/profiles/search`,
    method: 'POST',
    body: {
      jobtitles: req.jobTitles,
      searchTerms: req.jobTitlesFreeText,
      filter: req.filters,
      locations: filterDeepLocations,
      onlyPublishedCandidates: req.onlyPublishedCandidates,
      page: req.page,
      pageSize: req.limit,
      positions: [],
      orderBy: req.orderBy || [],
      searchCvContent: req.includeCvContent,
    },
  })
  return dataWithDeepLocations.data as IMatchSearchApiResponse
}

export const initialFacets: Partial<IJobFiltersResponse> = {
  extentsOfEmployment: [
    {
      name: 'fullTime',
      count: 0,
    },
    {
      name: 'partTime',
      count: 0,
    },
  ],
  formsOfEmployment: [
    {
      name: 'employee',
      count: 0,
    },
    {
      name: 'temporaryEmployee',
      count: 0,
    },
    {
      name: 'consultant',
      count: 0,
    },
    {
      name: 'freelance',
      count: 0,
    },
  ],
  otherPreferences: [
    {
      name: 'onSite',
      count: 0,
    },
    {
      name: 'remoteWork',
      count: 0,
    },
    {
      name: 'hybrid',
      count: 0,
    },
  ],
  publishingPermissions: [
    {
      name: 'showMyProfile',
      count: 0,
    },
    { name: 'doNotPublish', count: 0 },
  ],
}

export const mergeFacetsReducer = (initial: IFacet[], res: IFacet[]) =>
  initial?.reduce((acc, curr) => {
    const found = acc.find((resItem) => resItem.name === curr.name)
    if (found) {
      return acc
    }
    return [...acc, curr]
  }, res)

export const mergeInitialFacets = (
  facets: IJobFiltersResponse
): IJobFiltersResponse => ({
  ...facets,
  extentsOfEmployment: mergeFacetsReducer(
    initialFacets.extentsOfEmployment,
    facets.extentsOfEmployment
  ),
  formsOfEmployment: mergeFacetsReducer(
    initialFacets.formsOfEmployment,
    facets.formsOfEmployment
  ),
  otherPreferences: mergeFacetsReducer(
    initialFacets.otherPreferences,
    facets.otherPreferences
  ),
  publishingPermissions: mergeFacetsReducer(
    initialFacets.publishingPermissions,
    facets.publishingPermissions
  ),
})

const formsOfEmploymentSortOrder = [
  'employee',
  'temporaryEmployee',
  'consultant',
  'freelance',
]
const otherPreferencesSortOrder = ['onSite', 'remoteWork', 'hybrid']
const publishingPermissionsSortOrder = ['showMyProfile', 'doNotPublish']
const extentsOfEmploymentSortOrder = ['fullTime', 'partTime']

export const sortFacetsHelper = (facetList: IFacet[], sortOrder: string[]) =>
  facetList.sort(
    (a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name)
  )

export const sortFacets = (
  facets: IJobFiltersResponse
): IJobFiltersResponse => ({
  ...facets,
  formsOfEmployment: sortFacetsHelper(
    facets.formsOfEmployment,
    formsOfEmploymentSortOrder
  ),
  otherPreferences: sortFacetsHelper(
    facets.otherPreferences,
    otherPreferencesSortOrder
  ),
  publishingPermissions: sortFacetsHelper(
    facets.publishingPermissions,
    publishingPermissionsSortOrder
  ),
  extentsOfEmployment: sortFacetsHelper(
    facets.extentsOfEmployment,
    extentsOfEmploymentSortOrder
  ),
})

export const keepCurrentEmptyFacets =
  (currentFacets: IJobFiltersRequest) => (newFacets: IJobFiltersResponse) => {
    const newFacetsCopy = clone(newFacets)
    Object.keys(currentFacets).forEach((facetCategory) => {
      currentFacets[facetCategory as keyof IJobFiltersRequest].forEach(
        (facet) => {
          if (
            !newFacets[facetCategory as keyof IJobFiltersResponse].some(
              (facetItem) => facetItem.name === facet
            )
          ) {
            newFacetsCopy[facetCategory as keyof IJobFiltersResponse].push({
              name: facet,
              count: 0,
            })
          }
        }
      )
    })

    return newFacetsCopy
  }
