import {
  ICandidateListSlice,
  IJobFiltersRequest,
  IMatch,
} from '@local/Types/MatchingApi.types'
import { createSlice } from '@reduxjs/toolkit'

const removeFilter = (
  state: ICandidateListSlice,
  name: string,
  groupKey: keyof IJobFiltersRequest
): ICandidateListSlice => ({
  ...state,
  filters: {
    ...state.filters,
    [groupKey]: state.filters[groupKey]?.filter(
      (selectedFilter: string) => selectedFilter !== name
    ),
  },
})

const addFilter = (
  state: ICandidateListSlice,
  name: string,
  groupKey: keyof IJobFiltersRequest
): ICandidateListSlice => ({
  ...state,
  filters: {
    ...state.filters,
    [groupKey]: [...state.filters[groupKey], name],
  },
})

export const candidateListSlice = createSlice({
  name: 'candidateListSlice',
  initialState: {
    filters: {
      occupationGroups: [],
      formsOfEmployment: [],
      extentsOfEmployment: [],
      jobTitles: [],
      publishingPermissions: [],
      otherPreferences: [],
    },
    selectedCandidates: [],
  } as ICandidateListSlice,
  reducers: {
    toggleFilter: (state: ICandidateListSlice, action) => {
      const groupKey = action.payload.group as keyof IJobFiltersRequest

      if (
        state.filters?.[groupKey]?.some(
          (facet) => facet === action.payload.name
        )
      ) {
        return removeFilter(state, action.payload.name as string, groupKey)
      } else {
        return addFilter(state, action.payload.name as string, groupKey)
      }
    },
    addCandidate: (state: ICandidateListSlice, action) => ({
      ...state,
      selectedCandidates: [...state.selectedCandidates, action.payload],
    }),
    addCandidates: (state: ICandidateListSlice, action) => {
      const candidateIds = new Set(state.selectedCandidates.map((c) => c.id))
      return {
        ...state,
        selectedCandidates: [
          ...state.selectedCandidates,
          ...action.payload.filter((c: IMatch) => !candidateIds.has(c.id)),
        ],
      }
    },
    removeCandidate: (state: ICandidateListSlice, action) => ({
      ...state,
      selectedCandidates: state.selectedCandidates.filter(
        (candidate) => candidate.id !== action.payload.id
      ),
    }),
    removeCandidates: (state: ICandidateListSlice, action) => ({
      ...state,
      selectedCandidates: state.selectedCandidates.filter(
        (candidate) =>
          !action.payload.map((c: IMatch) => c.id).includes(candidate.id)
      ),
    }),
    removeAllCandidates: (state: ICandidateListSlice) => ({
      ...state,
      selectedCandidates: [],
    }),
    resetGroupByName: (state: ICandidateListSlice, action) => ({
      ...state,
      filters: { ...state.filters, [action.payload]: [] },
    }),
  },
})

export const {
  toggleFilter,
  resetGroupByName,
  addCandidate,
  addCandidates,
  removeCandidate,
  removeCandidates,
  removeAllCandidates,
} = candidateListSlice.actions
