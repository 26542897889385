import React, { ChangeEvent } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  styled,
} from '@mui/material'
import { InfoRounded } from '@mui/icons-material'
import parse from 'html-react-parser'

interface IIncludeCvContentCheckboxProps {
  checked: boolean
  onChange: (checked: boolean) => void
}

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  whiteSpace: 'pre-line',
  fontSize: theme.typography.body1.fontSize,
  '& ul': {
    margin: 0,
    paddingInlineStart: theme.spacing(3),
    '& li': {
      margin: 0,
    },
  },
}))

const IncludeCvContentCheckbox: React.FunctionComponent<
  IIncludeCvContentCheckboxProps
> = ({ checked, onChange }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false)

  const toggleInfoModal = () => {
    setIsInfoModalOpen((prev) => !prev)
  }

  const {
    sokKandidatNy: { inkluderaCv, inkluderaCvDialogContent },
  } = usePickEpiContent()

  const handleChange = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onChange(checked)
  }

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            color="primary"
            data-testid="include-cv-content-checkbox"
          />
        }
        label={
          <Stack spacing={0} direction="row" alignItems="center">
            <span>{inkluderaCv.checkboxLabel}</span>
            <IconButton
              size="small"
              onClick={toggleInfoModal}
              data-testid="info-dialog-button"
            >
              <InfoRounded fontSize="small" />
            </IconButton>
          </Stack>
        }
      />
      <Dialog
        open={isInfoModalOpen}
        onClose={toggleInfoModal}
        fullWidth
        sx={{ wordBreak: 'break-word' }}
      >
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {inkluderaCv.infoDialogTitle}
        </DialogTitle>
        <StyledDialogContent>
          {parse(inkluderaCvDialogContent.mainBody)}
        </StyledDialogContent>
      </Dialog>
    </>
  )
}

export default IncludeCvContentCheckbox
