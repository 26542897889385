import React, { useLayoutEffect, useState } from 'react'
import { Autocomplete, Box, InputLabel, TextField } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import debounce from 'lodash.debounce'
import parse from 'html-react-parser'
import { IDeepLocation, ILocation } from '@local/Types/MatchingApi.types'
import { useGetLocationsQuery } from '@local/FindCandidate/Api/MatchingApi'

interface LocationAutocompleteProps {
  locations: ILocation[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: any, newLocations: IDeepLocation[]) => void
}

export const LocationAutocomplete = ({
  locations = [],
  onChange,
}: LocationAutocompleteProps) => {
  const { label, placeholder } =
    usePickEpiContent().sokKandidatNy.arbetsortSearch

  const [locationSearchWord, setLocationSearchWord] = useState('')
  const [isDebouncing, setIsDebouncing] = useState(false)

  const { data, isFetching, isSuccess } = useGetLocationsQuery(
    locationSearchWord,
    {
      skip: Boolean(!locationSearchWord),
    }
  )

  const checkEquality = (option: IDeepLocation, value: IDeepLocation) =>
    option && value && option.name === value.name && option.type === value.type

  const locationInputChangeHandler = debounce((value: string) => {
    setLocationSearchWord(value)
  }, 300)

  useLayoutEffect(() => {
    if (!isFetching) {
      setIsDebouncing(false)
    }
  }, [isFetching])

  return (
    <Box>
      <InputLabel>{label}</InputLabel>
      <Autocomplete
        multiple
        autoHighlight
        options={!isFetching && !isDebouncing ? data ?? [] : []}
        isOptionEqualToValue={checkEquality}
        value={locations as IDeepLocation[]}
        onChange={onChange}
        onInputChange={(event, value) => locationInputChangeHandler(value)}
        getOptionLabel={(option: IDeepLocation) =>
          `${option.name}, ${option.type}`
        }
        noOptionsText={
          locationSearchWord && isSuccess && data?.length === 0
            ? 'Inga resultat'
            : undefined
        }
        renderOption={(props, option) => (
          <li {...props}>
            {parse(`${option.nameWithHighLightedSearchTerm}, ${option.type}`)}
          </li>
        )}
        open={locationSearchWord !== ''}
        popupIcon={null}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        data-testid="locations-autocomplete"
      />
    </Box>
  )
}
