/* eslint-disable @typescript-eslint/no-misused-promises */
import { IAdvisor, ICvPdf } from '@local/Profile/api/ProfileApi'
import {
  previewCvQuery,
  downloadCvQuery,
} from '@local/Profile/api/downloadFile'
import { Alert, Box, Typography, Button, Snackbar, Stack } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { IProfileCallToActionContent } from '@local/Types'
import { ProfileCandidateList } from '@local/Profile/components/ProfileCandidateList'
import { ICandidateListsApiModel } from '@local/AdvisorList/types'
import { createHrefLinkedIn } from '@local/Utils/Helpers'
import useGetRole from '@local/Utils/Hooks/useGetRole/useGetRole'
import CallToAction from '@local/Components/CallToAction/CallToAction'

interface ProfileCallToActionProps {
  advisor?: IAdvisor
  email: string
  mobilePhone: string
  linkedInUrl?: string
  cvPdf: ICvPdf
  userId: string
  userName: string
  content: IProfileCallToActionContent
  lists?: ICandidateListsApiModel[]
  listId: string
  onCreateList?: (name: string) => Promise<unknown>
  onAddToList?: (name: string, id: string) => Promise<unknown>
}

export const ProfileCallToAction: React.FC<ProfileCallToActionProps> = ({
  advisor,
  email,
  mobilePhone,
  linkedInUrl = '',
  cvPdf,
  userId,
  userName,
  content,
  lists = [],
  listId,
  onAddToList,
  onCreateList,
}) => {
  const [cvError, setCvError] = useState(false)

  const { isMedarbetare } = useGetRole()

  const handleCloseCvAlert = () => {
    setCvError(false)
  }

  const handleEmailKlient = () => {
    window.location.href = `mailto:${email}`
  }

  const previewCv = useCallback(async () => {
    await previewCvQuery({
      pdfId: cvPdf?.id,
      clientId: userId,
      candidateListId: listId,
      isMedarbetare,
    }).then((res) => setCvError(res))
  }, [cvPdf?.id, isMedarbetare, listId, userId])

  const downloadCv = useCallback(async () => {
    await downloadCvQuery({
      pdfId: cvPdf?.id,
      clientId: userId,
      clientName: userName,
      candidateListId: listId,
      isMedarbetare,
    }).then((res) => setCvError(res))
  }, [cvPdf?.id, isMedarbetare, listId, userId, userName])

  return (
    <>
      <Snackbar
        open={cvError}
        autoHideDuration={6000}
        onClose={handleCloseCvAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseCvAlert}
          severity="error"
          sx={{ width: '100%' }}
        >
          {content.cvErrorText}
        </Alert>
      </Snackbar>
      <Box>
        {lists.length > 0 && (
          <ProfileCandidateList
            lists={lists}
            onAddToList={onAddToList}
            onCreateList={onCreateList}
          />
        )}

        <Stack spacing={3}>
          <CallToAction title={content.contactInfoTitle}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ overflowWrap: 'break-word' }}
            >
              {email}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {mobilePhone}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={handleEmailKlient}
              sx={{ mt: 2 }}
            >
              {content.contactInfoEmailButton}
            </Button>
          </CallToAction>

          <CallToAction title={content.resumeInfoTitle}>
            <Stack spacing={2} alignItems="flex-start">
              {linkedInUrl.length > 0 && (
                <Button
                  variant="outlined"
                  size="small"
                  href={createHrefLinkedIn(linkedInUrl)}
                  target="_blank"
                >
                  {content.resumeInfoLinkedin}
                </Button>
              )}
              {cvPdf ? (
                <Stack direction="row" spacing={1}>
                  <Button variant="outlined" size="small" onClick={downloadCv}>
                    {content.resumeInfoDownloadCv}
                  </Button>
                  <Button variant="outlined" size="small" onClick={previewCv}>
                    {content.resumeInfoPreviewCv}
                  </Button>
                </Stack>
              ) : (
                <Alert severity="warning">
                  Kandidaten har inget uppladdat CV
                </Alert>
              )}
            </Stack>
          </CallToAction>

          {!!advisor && (
            <CallToAction title={content.advisorTitle}>
              <Typography variant="body1">{`${advisor.firstName} ${advisor.lastName}`}</Typography>
            </CallToAction>
          )}
        </Stack>
      </Box>
    </>
  )
}
