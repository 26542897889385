import {
  getMatchesMapper,
  keepCurrentEmptyFacets,
  mergeInitialFacets,
  sortFacets,
} from '@local/FindCandidate/Api/matchingApi.helpers'
import {
  IDeepLocation,
  IMatchSearchApiResponse,
  INewMatchSearchApiRequest,
} from '@local/Types/MatchingApi.types'
import { convertToSwedishTypesdRecursive } from '@local/Utils/Helpers'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react/'
import { pipe } from 'ramda'

export const matchingServiceApi = createApi({
  reducerPath: 'matchingServiceApi',
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    getMatches: builder.query<
      IMatchSearchApiResponse,
      INewMatchSearchApiRequest
    >({
      queryFn: async (
        {
          locations,
          jobTitles,
          jobTitlesFreeText,
          filters,
          onlyPublishedCandidates,
          page = 1,
          limit,
          currentFacet = '',
          currentFilters = {},
          includeCvContent,
        },
        _queryApi,
        _extraOptions,
        fetchData
      ) => {
        const matches = await getMatchesMapper(
          {
            locations,
            jobTitles,
            jobTitlesFreeText,
            filters,
            onlyPublishedCandidates,
            page,
            limit,
            includeCvContent,
            orderBy: ['relevance'],
          },
          fetchData
        )

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const hasFacetLocally = currentFilters?.[currentFacet] ?? false
        const filterCategoryToKeep = hasFacetLocally
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            { [currentFacet]: currentFilters?.[currentFacet] }
          : {}

        return {
          data: {
            ...matches,

            facets: {
              ...pipe(
                mergeInitialFacets,
                keepCurrentEmptyFacets(filters),
                sortFacets
              )(matches.facets),
              ...filterCategoryToKeep,
            },
          },
        }
      },
    }),
    getLocations: builder.query<IDeepLocation[], string>({
      query: (location) => ({
        url: `/referencedata/locations/suggest/${location}`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: IDeepLocation[]) =>
        response
          .map((loc) => convertToSwedishTypesdRecursive(loc))
          .filter((deepLoc) => {
            const locationsFromUrl = () => {
              const param = new URLSearchParams(location.search).get(
                'locations'
              )

              const paramOrEmpty = param ? param?.split('_') : []
              return paramOrEmpty?.filter(Boolean).map((l) => ({
                name: l.split(',')[0],
                type: l.split(',')[1],
              }))
            }
            if (locationsFromUrl().length) {
              const foundLoc = locationsFromUrl().find(
                (locInUrl) =>
                  locInUrl.name === deepLoc.name &&
                  locInUrl.type === deepLoc.type
              )
              return foundLoc ? false : true
            }
            return true
          }),
    }),
  }),
})

export const { useGetMatchesQuery, useGetLocationsQuery } = matchingServiceApi
