import { FilterBarDrawer } from '@local/FindCandidate/Components/FilterBar/FilterBarDrawer'
import { FilterOption } from '@local/FindCandidate/Components/FilterBar/FilterOption'
import { FilterOptionGroupAccordion } from '@local/FindCandidate/Components/FilterBar/FilterOptionGroupAccordion'
import { getActiveFilterCount } from '@local/FindCandidate/Components/Utils/filterHelper'
import { useSearchQueryParams } from '@local/FindCandidate/Hooks/useSearchQueryParams'
import {
  IJobFiltersRequest,
  IJobFiltersResponse,
} from '@local/Types/MatchingApi.types'
import { mapFacetsToOptions } from '@local/Utils/Helpers/typeHelper'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { Button } from '@mui/material'
import React from 'react'

interface MobileFilterProps {
  options: IJobFiltersResponse
  filterChange: (filterName: string, group: keyof IJobFiltersRequest) => void
}

export const MobileFilter = ({ options, filterChange }: MobileFilterProps) => {
  const { searchFilteringFacets, searchFiltering } =
    usePickEpiContent().sokKandidatNy
  const { formattedSearchParams, setSearchParams } = useSearchQueryParams()

  const activeFilterCount = getActiveFilterCount(formattedSearchParams)
  const disableClearButton = activeFilterCount === 0

  const resetAllFilters = () => {
    setSearchParams({
      extentsOfEmployment: [],
      formsOfEmployment: [],
      otherPreferences: [],
      occupationGroups: [],
      jobTitles: [],
      publishingPermissions: [],
    })
  }

  return (
    <FilterBarDrawer
      title={searchFiltering.allFiltersOptionTitle}
      activeFilterCount={activeFilterCount}
    >
      <Button
        size="small"
        variant="text"
        sx={{ p: 2 }}
        onClick={resetAllFilters}
        disabled={disableClearButton}
      >
        Rensa allt
      </Button>
      <FilterOptionGroupAccordion
        title={searchFiltering.occupationGroupsFilterTitle}
        selectCount={formattedSearchParams?.filters?.occupationGroups?.length}
      >
        <FilterOption
          selectedFilters={formattedSearchParams?.filters?.occupationGroups}
          options={mapFacetsToOptions(
            options?.occupationGroups,
            searchFilteringFacets
          )}
          onChangeFilter={(filterName: string) =>
            filterChange(filterName, 'occupationGroups')
          }
          buttonProps={{ sx: { pl: 0 } }}
        />
      </FilterOptionGroupAccordion>
      <FilterOptionGroupAccordion
        title={searchFiltering.jobTitlesFilterTitle}
        selectCount={formattedSearchParams?.filters?.jobTitles?.length}
      >
        <FilterOption
          selectedFilters={formattedSearchParams?.filters?.jobTitles}
          options={mapFacetsToOptions(
            options?.jobTitles,
            searchFilteringFacets
          )}
          onChangeFilter={(filterName: string) =>
            filterChange(filterName, 'jobTitles')
          }
          buttonProps={{ sx: { pl: 0 } }}
        />
      </FilterOptionGroupAccordion>
      <FilterOptionGroupAccordion
        title={searchFiltering.publishingPermissionsFilterTitle}
        selectCount={
          formattedSearchParams?.filters?.publishingPermissions?.length
        }
      >
        <FilterOption
          selectedFilters={
            formattedSearchParams?.filters?.publishingPermissions
          }
          options={mapFacetsToOptions(
            options?.publishingPermissions,
            searchFilteringFacets
          )}
          onChangeFilter={(filterName: string) =>
            filterChange(filterName, 'publishingPermissions')
          }
          buttonProps={{ sx: { pl: 0 } }}
        />
      </FilterOptionGroupAccordion>

      <FilterOptionGroupAccordion
        title={searchFiltering.otherFiltersFilterTitle}
        selectCount={
          formattedSearchParams?.filters?.extentsOfEmployment?.length +
          formattedSearchParams?.filters?.formsOfEmployment?.length +
          formattedSearchParams?.filters?.otherPreferences?.length
        }
      >
        <FilterOption
          subtitle={searchFiltering.extentsOfEmploymentFilterOption}
          selectedFilters={formattedSearchParams?.filters?.extentsOfEmployment}
          options={mapFacetsToOptions(
            options?.extentsOfEmployment,
            searchFilteringFacets
          )}
          onChangeFilter={(filterName: string) =>
            filterChange(filterName, 'extentsOfEmployment')
          }
          buttonProps={{ sx: { pl: 0 } }}
        />
        <FilterOption
          subtitle={searchFiltering.formsOfEmploymentFilterOption}
          selectedFilters={formattedSearchParams?.filters?.formsOfEmployment}
          options={mapFacetsToOptions(
            options?.formsOfEmployment,
            searchFilteringFacets
          )}
          onChangeFilter={(filterName: string) =>
            filterChange(filterName, 'formsOfEmployment')
          }
          buttonProps={{ sx: { pl: 0 } }}
        />
        <FilterOption
          subtitle={searchFiltering.otherPreferencesFilterOption}
          selectedFilters={formattedSearchParams?.filters?.otherPreferences}
          options={mapFacetsToOptions(
            options?.otherPreferences,
            searchFilteringFacets
          )}
          onChangeFilter={(filterName: string) =>
            filterChange(filterName, 'otherPreferences')
          }
          buttonProps={{ sx: { pl: 0 } }}
        />
      </FilterOptionGroupAccordion>
    </FilterBarDrawer>
  )
}
