import {
  ApiActivateCandidateListModel,
  ApiAddCandidateModel,
  ApiAddCandidatesModel,
  ApiCreateCandidateListModel,
  ApiCreateEmailDraft,
  ApiRemoveCandidateModel,
  ApiUpdateCandidateListNameModel,
  ICandidateListsApiModel,
} from '@local/AdvisorList/types'
import { pushFeedback } from '@local/Components/Toaster/Toaster'
import {
  CandidateList,
  CandidateListRequest,
} from '@local/Types/MatchingApi.types'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react/'
import { omit } from 'ramda'

export const candidateListApi = createApi({
  reducerPath: 'candidateListApi',
  baseQuery: trrFetchBaseQuery(),
  tagTypes: ['AdvisorLists', 'CandidateList'],
  endpoints: (builder) => ({
    getCandidateLists: builder.query<ICandidateListsApiModel[], void>({
      providesTags: ['AdvisorLists'],
      query: () => `/clientmatch/candidatelists`,
    }),
    getCandidateListById: builder.query<CandidateList, CandidateListRequest>({
      providesTags: ['CandidateList'],
      query: ({ listId, page, pageSize }) => ({
        url: `/clientmatch/candidatelists/${listId}`,
        params: { page, pageSize },
      }),
    }),
    createCandidateList: builder.mutation<
      ICandidateListsApiModel,
      ApiCreateCandidateListModel
    >({
      query: (candidateList) => ({
        url: `/clientmatch/candidatelists`,
        method: 'POST',
        body: candidateList,
      }),
      invalidatesTags: ['AdvisorLists', 'CandidateList'],
      onQueryStarted: async ({ name }, { queryFulfilled }) => {
        await queryFulfilled
          .then(() =>
            pushFeedback({
              body: `Kandidaten har lagts till i "${name}"`,
            })
          )
          .catch(() =>
            pushFeedback({
              type: 'error',
              body: `Kandidaten kunde inte läggas till. Listan "${name}" har inte skapats.`,
            })
          )
      },
    }),
    updateCandidateListName: builder.mutation<
      void,
      ApiUpdateCandidateListNameModel
    >({
      query: (updatedList) => ({
        url: `/clientmatch/candidatelists/${updatedList.id}`,
        method: 'PUT',
        body: omit(['id'], updatedList),
      }),
      invalidatesTags: ['AdvisorLists', 'CandidateList'],
    }),
    deleteCandidateList: builder.mutation<void, string>({
      query: (listId) => ({
        url: `/clientmatch/candidatelists/${listId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AdvisorLists'],
    }),
    addCandidateToList: builder.mutation<void, ApiAddCandidateModel>({
      query: (input) => ({
        url: `/clientmatch/candidatelists/${input.listId}/candidates`,
        method: 'POST',
        body: input.candidate,
      }),
      invalidatesTags: ['AdvisorLists', 'CandidateList'],
      onQueryStarted: async ({ listName }, { queryFulfilled }) => {
        await queryFulfilled
          .then(() =>
            pushFeedback({
              body: `Kandidaten har lagts till i "${listName}"`,
            })
          )
          .catch(() =>
            pushFeedback({
              type: 'error',
              body: `Kandidaten kunde inte läggas till i "${listName}"`,
            })
          )
      },
    }),
    addCandidatesToList: builder.mutation<void, ApiAddCandidatesModel>({
      query: (input) => ({
        url: `/clientmatch/candidatelists/${input.listId}/candidates`,
        method: 'POST',
        body: { candidates: input.candidates },
      }),
      invalidatesTags: ['AdvisorLists', 'CandidateList'],
      onQueryStarted: async ({ listName }, { queryFulfilled }) => {
        await queryFulfilled
          .then(() =>
            pushFeedback({
              body: `Kandidaterna har lagts till i "${listName}"`,
            })
          )
          .catch(() =>
            pushFeedback({
              type: 'error',
              body: `Kandidaterna kunde inte läggas till i "${listName}"`,
            })
          )
      },
    }),
    removeCandidateFromList: builder.mutation<
      CandidateList,
      ApiRemoveCandidateModel
    >({
      query: ({ listId, candidateId }) => ({
        url: `/clientmatch/candidatelists/${listId}/candidates/${candidateId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AdvisorLists', 'CandidateList'],
    }),
    activateExternal: builder.mutation<void, ApiActivateCandidateListModel>({
      query: ({ listId, activate }) => ({
        url: `/clientmatch/candidatelists/${listId}/activate`,
        method: 'PUT',
        body: { activate },
      }),
      invalidatesTags: ['AdvisorLists', 'CandidateList'],
      onQueryStarted: async ({ activate }, { queryFulfilled }) => {
        await queryFulfilled
          .catch(() =>
            pushFeedback({
              type: 'error',
              body: `Listan kunde inte ${
                activate ? 'aktiverats' : 'inaktiverats'
              }`,
            })
          )
          .then(() =>
            pushFeedback({
              body: `Listan har ${
                activate ? 'aktiverats' : 'inaktiverats'
              } för externt bruk`,
            })
          )
      },
    }),
    createEmailDraft: builder.mutation<void, ApiCreateEmailDraft>({
      query: ({ listId, subject }) => ({
        url: `/clientmatch/candidatelists/${listId}/email`,
        method: 'POST',
        body: { emailSubject: subject },
      }),
      invalidatesTags: ['CandidateList'],
      onQueryStarted: async (_, { queryFulfilled }) => {
        await queryFulfilled
          .catch(() =>
            pushFeedback({
              type: 'error',
              body: 'Ett utkast kunde inte skapas i Outlook',
            })
          )
          .then(() =>
            pushFeedback({
              body: 'Ett utkast har skapats i Outlook',
            })
          )
      },
    }),
  }),
})

export const {
  useGetCandidateListsQuery,
  useGetCandidateListByIdQuery,
  useCreateCandidateListMutation,
  useUpdateCandidateListNameMutation,
  useDeleteCandidateListMutation,
  useAddCandidateToListMutation,
  useAddCandidatesToListMutation,
  useRemoveCandidateFromListMutation,
  useActivateExternalMutation,
  useCreateEmailDraftMutation,
} = candidateListApi
