import React, { useState } from 'react'
import RemoveCandidateDialog from '@local/CandidateList/components/RemoveList/RemoveCandidateDialog/RemoveCandidateDialog'
import { isValidCandidate } from '@local/CandidateList/components/Utils/candidateHelpers'
import DeletableTableRow from '@local/Components/DeletableTableRow/DeletableTableRow'
import { MultipleTextItems } from '@local/FindCandidate/Components/CandidateList/MultipleTextItems'
import { ICandidate } from '@local/Types/MatchingApi.types'
import { PeopleAlt } from '@mui/icons-material'
import { TableCell, TableRow, Tooltip, Typography, styled } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useWindowSize } from '@local/Utils/Hooks/useWindowSize'

const ConsentGivenIcon = styled(PeopleAlt)({
  width: 15,
  height: 15,
})

const CustomTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{
  isMobile?: boolean
}>(({ isMobile = false }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: isMobile ? '240px' : undefined,
  maxWidth: isMobile ? '240px' : undefined,
  borderStyle: isMobile ? 'border-box' : undefined,
}))

const PointerTableRow = styled(TableRow)({
  cursor: 'pointer',
})

const EllipsisText = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '70vw',
})

interface TableCandidateListItemProps {
  candidate: ICandidate
  onClick: (id: string) => void
  onDelete?: () => void
}

export const TableCandidateListItem = ({
  candidate,
  onDelete,
  onClick,
}: TableCandidateListItemProps) => {
  const { isDesktop } = useWindowSize()
  const [isRemoveCandidateDialogOpen, setIsRemoveCandidateDialogOpen] =
    useState(false)

  const { consentGivenTooltip } = usePickEpiContent().candidateList.candidate

  const isValid = isValidCandidate(candidate)
  const isExternalList = onDelete === undefined
  const TableRowComponent = isExternalList ? PointerTableRow : DeletableTableRow

  const handleOnClick = () => {
    isValid && onClick(candidate.id)
  }

  const handleToggleDeleteDialog = () => {
    setIsRemoveCandidateDialogOpen(!isRemoveCandidateDialogOpen)
  }

  return (
    <>
      <TableRowComponent
        key={candidate.id}
        onClick={handleOnClick}
        disabled={!isValid}
        hover
        data-testid="candidate-list-item"
        onDelete={handleToggleDeleteDialog}
      >
        {isDesktop ? (
          <>
            <CustomTableCell>
              <EllipsisText>{candidate.name}</EllipsisText>
            </CustomTableCell>
            <TableCell scope="row" padding="normal">
              <MultipleTextItems
                items={candidate?.jobTitles?.map((job) => job.name)}
                icon={
                  candidate.isPublished && !isExternalList ? (
                    <Tooltip title={consentGivenTooltip}>
                      <ConsentGivenIcon data-testid="published-icon" />
                    </Tooltip>
                  ) : null
                }
              />
            </TableCell>
          </>
        ) : (
          <CustomTableCell isMobile>
            <EllipsisText>{candidate.name}</EllipsisText>
            <MultipleTextItems
              items={candidate?.jobTitles?.map((job) => job.name)}
              icon={
                candidate.isPublished && !isExternalList ? (
                  <Tooltip title={consentGivenTooltip}>
                    <ConsentGivenIcon data-testid="published-icon" />
                  </Tooltip>
                ) : null
              }
              customCharLimit={24}
            />
          </CustomTableCell>
        )}
      </TableRowComponent>
      {isRemoveCandidateDialogOpen && (
        <RemoveCandidateDialog
          fullName={candidate.name}
          onToggle={handleToggleDeleteDialog}
          onRemoveCandidate={onDelete}
        />
      )}
    </>
  )
}
